/* Injects Tailwind's base styles & any base styles registered by plugins. */
@tailwind base;

@layer base {
	h1 {
		@apply text-2xl mb-8;
	}

	h2 {
		@apply text-xl mb-4;
	}

	h3 {
		@apply text-lg font-bold mb-4 text-primary;
	}

	h4 {
		@apply font-bold;
	}

	ul {
		@apply list-disc list-inside;
	}

	ol {
		@apply list-decimal list-inside;
	}

	li > p:first-child {
		@apply inline;
	}
	ul ul li {
		@apply ml-4;
	}
	hr {
		@apply my-2;
	}
	.contentPart {
		@apply py-2 md:py-16 lg:py-32;
	}
}

/* Injects Tailwind's component classes & any component classes registered by plugins. */
@tailwind components;

@layer components {
	/* custom CSS goes here */
}

/* Injects Tailwind's utility classes & any utility classes registered by plugins. */
@tailwind utilities;

@layer utilities {
	/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap'); */
	/* cyrillic-ext */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 400;
		font-display: swap;
		src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format('woff2');
		unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
	}
	/* cyrillic */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 400;
		font-display: swap;
		src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');
		unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
	}
	/* vietnamese */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 400;
		font-display: swap;
		src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format('woff2');
		unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
			U+1EA0-1EF9, U+20AB;
	}
	/* latin-ext */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 400;
		font-display: swap;
		src: url(/fonts/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');
		unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
			U+2C60-2C7F, U+A720-A7FF;
	}
	/* latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 400;
		font-display: swap;
		src: url(/fonts/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
			U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}
	/* cyrillic-ext */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 500;
		font-display: swap;
		src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format('woff2');
		unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
	}
	/* cyrillic */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 500;
		font-display: swap;
		src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');
		unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
	}
	/* vietnamese */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 500;
		font-display: swap;
		src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format('woff2');
		unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
			U+1EA0-1EF9, U+20AB;
	}
	/* latin-ext */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 500;
		font-display: swap;
		src: url(/fonts/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');
		unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
			U+2C60-2C7F, U+A720-A7FF;
	}
	/* latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 500;
		font-display: swap;
		src: url(/fonts/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
			U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}
	/* cyrillic-ext */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-display: swap;
		src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format('woff2');
		unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
	}
	/* cyrillic */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-display: swap;
		src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');
		unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
	}
	/* vietnamese */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-display: swap;
		src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format('woff2');
		unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
			U+1EA0-1EF9, U+20AB;
	}
	/* latin-ext */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-display: swap;
		src: url(/fonts/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');
		unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
			U+2C60-2C7F, U+A720-A7FF;
	}
	/* latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-display: swap;
		src: url(/fonts/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
			U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	/* open-sans-regular - latin */
	/* @font-face {
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	/* src: url('/fonts/Montserrat/Montserrat-Regular.ttf'); */
	/* src: url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu72xKOzY.woff2) */
	/* format('woff2'); */
	/* src: local('MontserratRegular') local('Montserrat-Regular'); */
	/* } */

	/* open-sans-700 - latin */
	/* @font-face {
	font-family: 'Montserrat', sans-serif;
	font-style: bold;
	font-weight: 700;
	font-display: swap;
	/* src: url('/fonts/Montserrat/Montserrat-Bold.ttf'); */
	/* src: local('MontserratBold'), local('Montserrat-Bold'); */
	/* } */

	/* open-sans-700 - latin */
	/* @font-face {
	font-family: 'Montserrat', sans-serif;
	font-style: semi-bold;
	font-weight: 600;
	font-display: swap;
	src: url('/fonts/Montserrat/Montserrat-SemiBold.ttf'); */
	/* src: local('MontserratBold'), local('Montserrat-Bold'); */
	/* } */

	img[lazy='loaded'] {
		-webkit-animation-duration: 0.5s;
		animation-duration: 0.5s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation-name: fadeInDown;
		animation-name: fadeInDown;
	}

	img[lazy='loading'] {
		color: transparent;
	}

	html * {
		font-family: 'Montserrat', Arial, Helvetica, sans-serif;
		font-display: swap;
	}

	@keyframes fadeInDown {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	body {
		overflow-x: hidden;
	}
	html::-webkit-scrollbar {
		width: 10px;
	}

	html::-webkit-scrollbar-thumb {
		background-color: var(--s);
		border-radius: 2px;
	}

	html::-webkit-scrollbar-track {
		background-color: var(--p);
	}

	html {
		scrollbar-width: 8px;
		scrollbar-color: var(--pf);
		scrollbar-track-color: var(--p);
	}

	.toasted-info {
		background-color: cadetblue;
	}

	::-moz-selection {
		/* Code for Firefox */
		color: var(--pc);
		background: var(--p);
	}

	::selection {
		color: var(--pc);
		background: var(--p);
	}
}

/* Directive controlling where Tailwind injects responsive variations of utilities.
   By default Tailwind normally append these at the end of your stylesheet. */
@tailwind screens;
